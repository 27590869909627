
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Entry": [
      "ProductDetailsCard",
      "ProductNav",
      "Navigation",
      "ContentAggregate",
      "Promo",
      "PromoBanner",
      "CtaWithDriftInteraction",
      "CtaWithUrlSet",
      "CtaWithUrl",
      "Headband",
      "PromotionalText",
      "ProductBlock",
      "ProductBlockSet",
      "Image",
      "ProductRecommendationsCohort",
      "ProductRecommendationsTile",
      "ImageGallery",
      "Video",
      "BreakpointVideo",
      "PromoHero",
      "PromoHeroSet",
      "Hero",
      "MediaSection",
      "Track",
      "HeaderBodyImageWithLink",
      "HeaderBodyImageWithLinkGallery",
      "DigitalCheckout",
      "Section",
      "DigitalPromotion",
      "Forms",
      "KeyValue",
      "Product",
      "Faq",
      "QAndA",
      "LegalText",
      "HeaderBody",
      "CaptureFormMessaging",
      "CaptureForm",
      "Shop",
      "ContentByCfu",
      "LongHeaderBodyFootnote",
      "OtdLookup",
      "ValuePropSet",
      "ValueProp",
      "SvgIcon",
      "HeaderBodyImage",
      "HeaderBodyImageGallery",
      "RegistrationPage",
      "DigitalLink",
      "ExternalLink",
      "StudioSiteLink",
      "WwwLink",
      "ComponentLeadGen",
      "JsonKeyValue",
      "MarkdownPage",
      "StaticAsset",
      "Metadata",
      "AccountMetadata",
      "PressQuote",
      "QuoteBanner",
      "ContentType2LKn3Xvlo4UCmGcweGaCyM",
      "ContentType1OJlz6NgUyeeaIk8Uci2Ow",
      "BoardOfDirector",
      "ContentType1VcIyFnR8OC2Imaa2Su2W2",
      "Programs",
      "PackageToSlugMapping",
      "ModuleConfiguration",
      "AvailableProducts",
      "Review",
      "ReviewSection",
      "Modal",
      "CompanyPageText",
      "AccountKeyValue",
      "ContentType3FI5SLqlYIeaKguyIkeyAm",
      "Banner"
    ],
    "_Node": [
      "ProductDetailsCard",
      "ProductNav",
      "Navigation",
      "ContentAggregate",
      "Promo",
      "PromoBanner",
      "CtaWithDriftInteraction",
      "CtaWithUrlSet",
      "CtaWithUrl",
      "Headband",
      "PromotionalText",
      "ProductBlock",
      "ProductBlockSet",
      "Image",
      "ProductRecommendationsCohort",
      "ProductRecommendationsTile",
      "ImageGallery",
      "Video",
      "BreakpointVideo",
      "PromoHero",
      "PromoHeroSet",
      "Hero",
      "MediaSection",
      "Track",
      "HeaderBodyImageWithLink",
      "HeaderBodyImageWithLinkGallery",
      "DigitalCheckout",
      "Section",
      "DigitalPromotion",
      "Forms",
      "KeyValue",
      "Product",
      "Faq",
      "QAndA",
      "LegalText",
      "HeaderBody",
      "CaptureFormMessaging",
      "CaptureForm",
      "Shop",
      "ContentByCfu",
      "LongHeaderBodyFootnote",
      "OtdLookup",
      "ValuePropSet",
      "ValueProp",
      "SvgIcon",
      "HeaderBodyImage",
      "HeaderBodyImageGallery",
      "RegistrationPage",
      "DigitalLink",
      "ExternalLink",
      "StudioSiteLink",
      "WwwLink",
      "ComponentLeadGen",
      "JsonKeyValue",
      "MarkdownPage",
      "StaticAsset",
      "Metadata",
      "AccountMetadata",
      "PressQuote",
      "QuoteBanner",
      "ContentType2LKn3Xvlo4UCmGcweGaCyM",
      "ContentType1OJlz6NgUyeeaIk8Uci2Ow",
      "BoardOfDirector",
      "ContentType1VcIyFnR8OC2Imaa2Su2W2",
      "Programs",
      "PackageToSlugMapping",
      "ModuleConfiguration",
      "AvailableProducts",
      "Review",
      "ReviewSection",
      "Modal",
      "CompanyPageText",
      "AccountKeyValue",
      "ContentType3FI5SLqlYIeaKguyIkeyAm",
      "Banner"
    ],
    "PromoBannerLinksItem": [
      "CtaWithDriftInteraction",
      "CtaWithUrl"
    ],
    "CtaWithUrlSetCtasItem": [
      "CtaWithDriftInteraction",
      "CtaWithUrl"
    ],
    "ImageGalleryImagesItem": [
      "Image",
      "Video"
    ],
    "PromoHeroBackgroundMedia": [
      "BreakpointVideo",
      "Image"
    ],
    "CtaWithUrlLink": [
      "DigitalLink",
      "ExternalLink",
      "StudioSiteLink",
      "WwwLink"
    ],
    "ContentAggregateAggregateItem": [
      "ComponentLeadGen",
      "ContentAggregate",
      "CtaWithUrl",
      "CtaWithUrlSet",
      "HeaderBodyImage",
      "HeaderBodyImageWithLink",
      "Image",
      "JsonKeyValue",
      "KeyValue",
      "LegalText",
      "LongHeaderBodyFootnote",
      "Navigation",
      "ProductBlock",
      "ProductBlockSet",
      "ProductRecommendationsCohort",
      "PromotionalText",
      "Shop",
      "SvgIcon",
      "Video"
    ],
    "NavigationSubnavsItem": [
      "CtaWithUrlSet",
      "Navigation",
      "ProductNav"
    ],
    "NavigationAriaLabel": [
      "KeyValue",
      "PromotionalText"
    ],
    "NavigationLogoAriaLabel": [
      "KeyValue",
      "PromotionalText"
    ]
  }
};
      export default result;
    